import React from 'react';
import { Router } from "@reach/router"

import App from './App'
import HomePage from './HomePage';

const AppRouter = () => (
    <Router>
      <App path='/:uid' />
      <HomePage path='user/:user' />
    </Router>
  );
  
  export default AppRouter;