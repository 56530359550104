
import {
    RETRIEVE_DESIGNS_PENDING,
    RETRIEVE_DESIGNS_SUCCESS,
    RETRIEVE_DESIGNS_FAILURE,

    READ_DESIGNS_PENDING,
    READ_DESIGNS_SUCCESS,
    READ_DESIGNS_FAILURE,

    UPDATE_DESIGNS_PENDING,
    UPDATE_DESIGNS_SUCCESS,
    UPDATE_DESIGNS_FAILURE,

    DELETE_DESIGNS_PENDING,
    DELETE_DESIGNS_SUCCESS,
    DELETE_DESIGNS_FAILURE,

    ARCHIVE_DESIGNS_PENDING,
    ARCHIVE_DESIGNS_SUCCESS,
    ARCHIVE_DESIGNS_FAILURE,

} from '../constants/constants';

//====================================================================//

const initialState = {

    designDataState: {
        pending: false,
        success: false,
        failure: false
    },
    deleteState: {
        pending: false,
        success: false,
        failure: false
    },
    updateState: {
        pending: false,
        success: false,
        failure: false
    },
    archiveState: {
        pending: false,
        success: false,
        failure: false
    },

    designData: null,
    deleteData: null,
    update: null,


    //====================================================================//

};

const designReducer = (state = initialState, action) => {

    switch (action.type) {

        case RETRIEVE_DESIGNS_PENDING:
            return { ...state, designDataState: { pending: true, success: false, failure: false } };
        case RETRIEVE_DESIGNS_SUCCESS:
            return { ...state, designData: action.response._data, designDataState: { pending: false, success: true, failure: false } };
        case RETRIEVE_DESIGNS_FAILURE:
            return { ...state, designData: null, designDataState: { pending: false, success: false, failure: true } };

        case READ_DESIGNS_PENDING:
            return { ...state, designDataState: { pending: true, success: false, failure: false } };
        case READ_DESIGNS_SUCCESS:
            return { ...state, designData: action.response._data, designDataState: { pending: false, success: true, failure: false } };
        case READ_DESIGNS_FAILURE:
            return { ...state, designData: null, designDataState: { pending: false, success: false, failure: true } };

        case UPDATE_DESIGNS_PENDING:
            return { ...state, updateState: { pending: true, success: false, failure: false } };
        case UPDATE_DESIGNS_SUCCESS:
            return { ...state, designData: action.response._data, updateState: { pending: false, success: true, failure: false } };
        case UPDATE_DESIGNS_FAILURE:
            return { ...state, designData: null, update: action.response.data._msg, updateState: { pending: false, success: false, failure: true } };

        case DELETE_DESIGNS_PENDING:
            return { ...state, deleteState: { pending: true, success: false, failure: false } };
        case DELETE_DESIGNS_SUCCESS:
            return { ...state, designData: action.response._data, deleteState: { pending: false, success: true, failure: false } };
        case DELETE_DESIGNS_FAILURE:
            return { ...state, designData: null, deleteData: action.response.data._msg, deleteState: { pending: false, success: false, failure: true } };

        case ARCHIVE_DESIGNS_PENDING:
            return { ...state, archiveState: { pending: true, success: false, failure: false } };
        case ARCHIVE_DESIGNS_SUCCESS:
            return { ...state, designData: null, archiveState: { pending: false, success: true, failure: false } };
        case ARCHIVE_DESIGNS_FAILURE:
            return { ...state, designData: null, archiveState: { pending: false, success: false, failure: true } };


        default:
            return state;
    }
};

export default designReducer;