import React, { useState, useEffect } from 'react';
import { useParams } from "@reach/router";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Box, Card, CardContent, CardMedia, ListItem, Checkbox, Link, Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import AppBar from '@mui/material/AppBar';
import AddBoxIcon from '@material-ui/icons/AddCircleTwoTone';
import CachedIcon from '@material-ui/icons/Cached';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import _ from 'lodash';
import actions from '../redux/actions/actions';
import ObjectRow from './ObjectRow';

const Img = styled('img')({
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    resizeMode: 'cover',
    height: '450px',
});

const DataPage = ({ sourceImageOpen, disableFlag, lastUidOpener, designData, handleChange, objectChange }) => {

    const classes = useStyles();

    return (
        designData &&
        <div>
            <AppBar style={{ backgroundColor: '#ffffff', boxShadow: '0px 0px 0px' }} position="sticky">
                <Table>
                    <TableBody>
                        <TableRow className={classes.uid}>
                            <Typography className={classes.rowTitle}>
                                UID : {designData.uid}
                            </Typography>
                        </TableRow>

                        <TableRow className={classes.image}>
                            <TableCell className={classes.imageCell}>
                                <Button
                                    disabled={disableFlag}
                                    variant="outlined"
                                    onClick={() => lastUidOpener()}> Cached Design&nbsp;
                                    <CachedIcon />
                                    <OpenInNewIcon />
                                </Button>
                            </TableCell>
                            <TableCell className={classes.imageCell1}>
                                <Img src={designData.url} />
                            </TableCell>
                            <TableCell className={classes.imageCell}>
                                <div className={classes.imageCell2}>
                                    <Button
                                        onClick={() => sourceImageOpen(designData.url)}> Design Source
                                        <OpenInNewIcon />
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </AppBar>

            <Table className={classes.table}>
                <TableBody>
                    <TableRow className={classes.measurement}>
                        <TableCell className={classes.col}></TableCell>

                        <TableCell className={classes.col}>
                            <Typography className={classes.uid_text}>
                                depth
                            </Typography>
                        </TableCell>

                        <TableCell className={classes.col}>
                            <Typography className={classes.uid_text}>
                                width
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.col}>
                            <Typography className={classes.uid_text}>
                                front_ratio
                            </Typography>
                        </TableCell>

                        <TableCell className={classes.col}>
                            <Typography className={classes.uid_text}>
                                Height
                            </Typography>
                        </TableCell>

                    </TableRow>

                    <TableRow className={classes.measurement}>
                        <TableCell className={classes.col}>
                            <Typography className={classes.rowTitle}>
                                Measurements:
                            </Typography>
                        </TableCell>

                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={designData.measurements.depth}
                                    variant="filled"
                                    onChange={e => handleChange('measurements', 'depth', e.target.value)}
                                />
                            }

                        </TableCell>

                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={designData.measurements.width}
                                    variant="filled"
                                    onChange={e => handleChange('measurements', 'width', e.target.value)}
                                />
                            }
                        </TableCell>

                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={designData.measurements.front_ratio}
                                    variant="filled"
                                    onChange={e => handleChange('measurements', 'front_ratio', e.target.value)}
                                />
                            }

                        </TableCell>
                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={designData.measurements.height}
                                    variant="filled"
                                    onChange={e => handleChange('measurements', 'height', e.target.value)}
                                />
                            }
                        </TableCell>

                    </TableRow>

                    <TableRow className={classes.measurement}>
                        <TableCell className={classes.col}></TableCell>

                        <TableCell className={classes.col}>
                            <Typography className={classes.uid_text}>
                                floor
                            </Typography>
                        </TableCell>

                        <TableCell className={classes.col}>
                            <Typography className={classes.uid_text}>
                                frontal
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.col}>
                            <Typography className={classes.uid_text}>
                                left
                            </Typography>
                        </TableCell>

                        <TableCell className={classes.col}>
                            <Typography className={classes.uid_text}>
                                right
                            </Typography>
                        </TableCell>

                    </TableRow>

                    <TableRow className={classes.measurement}>
                        <TableCell className={classes.col}>
                            <Typography className={classes.rowTitle}>
                                Brightness:
                            </Typography>
                        </TableCell>

                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={designData.brightness.floor}
                                    variant="filled"
                                    onChange={e => handleChange('brightness', 'floor', e.target.value)}
                                />
                            }

                        </TableCell>

                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={designData.brightness.frontal}
                                    variant="filled"
                                    onChange={e => handleChange('brightness', 'frontal', e.target.value)}
                                />
                            }
                        </TableCell>

                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={designData.brightness.left}
                                    variant="filled"
                                    onChange={e => handleChange('brightness', 'left', e.target.value)}
                                />
                            }

                        </TableCell>
                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={designData.brightness.right}
                                    variant="filled"
                                    onChange={e => handleChange('brightness', 'right', e.target.value)}
                                />
                            }
                        </TableCell>

                    </TableRow>

                    <TableRow className={classes.measurement}>
                        <TableCell className={classes.col}></TableCell>

                        <TableCell className={classes.col}>
                            <Typography className={classes.uid_text}>
                                klassisch
                            </Typography>
                        </TableCell>

                        <TableCell className={classes.col}>
                            <Typography className={classes.uid_text}>
                                modern
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.col}>
                            <Typography className={classes.uid_text}>
                                naturell
                            </Typography>
                        </TableCell>

                        <TableCell className={classes.col}>
                            <Typography className={classes.uid_text}>
                                urban
                            </Typography>
                        </TableCell>

                    </TableRow>

                    <TableRow className={classes.measurement}>
                        <TableCell className={classes.col}>
                            <Typography className={classes.rowTitle}>
                                style:
                            </Typography>
                        </TableCell>

                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={designData.style.klassisch}
                                    variant="filled"
                                    onChange={e => handleChange('style', 'klassisch', e.target.value)}
                                />
                            }

                        </TableCell>

                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={designData.style.modern}
                                    variant="filled"
                                    onChange={e => handleChange('style', 'modern', e.target.value)}
                                />
                            }
                        </TableCell>

                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={designData.style.naturell}
                                    variant="filled"
                                    onChange={e => handleChange('style', 'naturell', e.target.value)}
                                />
                            }

                        </TableCell>
                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={designData.style.urban}
                                    variant="filled"
                                    onChange={e => handleChange('style', 'urban', e.target.value)}
                                />
                            }
                        </TableCell>

                    </TableRow>
                </TableBody>
            </Table>
            {
                designData &&
                designData.objects.map((article, i) => {
                    return (
                        <div key={i}>
                            <ObjectRow
                                designData={designData}
                                article={article}
                                objectChange={objectChange}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'

    },
    table: {
        paddingTop: '500px',

    },
    uid: {
        paddingTop: '100px',
        textAlign: 'center',
        alignItems: 'center'
    },
    uid_text: {
        textAlign: 'left',
        justifyContent: 'center',
        fontSize: '12'
    },
    rowTitle: {
        textAlign: 'center',
        fontSize: '12',
        color: '#000000'
    },
    image: {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '#000000'
    },
    imageCell: {
        width: '33%',
        borderBottom: '#000000',
        paddingTop: '10%'
    },
    imageCell2: {
        paddingLeft: '35%'
    },
    imageCell1: {
        width: '33%',
        paddingLeft: '150px',
        paddingTop: '10px',
        borderBottom: '#000000'
    },
    measurement: {
        display: 'flex',
        flexDirection: 'row',
    },
    col: {
        width: '20%'
    },



}));



export default DataPage
