import React from "react";
import { Dialog, DialogContent, DialogTitle, CircularProgress } from "@material-ui/core";

/* COMPONENT: displaying template details on a dialog box */  
const ModalView = ({open, headerText}) => {
  return (
    <div>
      <Dialog open={open}>
        {
          headerText &&  
          <DialogTitle id="customized-dialog-title" style={{ textAlign: "center", alignContent: 'space-between' }}>
            {headerText}
          </DialogTitle>
        }
        <DialogContent  style={{ textAlign: "center", alignContent: 'space-between' }}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </div>
  ); 
}

export default ModalView;