import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import _ from 'lodash';
import { useParams } from "@reach/router";

import actions from './redux/actions/actions';
import DataPage from './components/DataPage';
import Buttons from './components/Buttons';
import ModalView from './components/ModalView';


const App = ({ archiveState, archiveDesignData, deleteState, updateState, designDataState, readDesignData, designData, updateDesignData, deleteDesignData, update, del }) => {

  const classes = useStyles();
  const [designValue, setDesignValue] = useState();
  const [modalState, setModalState] = useState(false);
  const [bufferFlag, setbufferFlag] = useState(true);
  const [uidBuffer, setUidBuffer] = useState();
  let { uid } = useParams();
  const { pending, success, failure } = designDataState;


  useEffect(() => {
    if (_.isEmpty(designData)) {
      !_.isEmpty(uid) && readDesignData(uid);
    }
  }, [uid]);

  useEffect(() => {
    pending && setModalState(true);
    success && setTimeout(() => {
      setDesignValue(designData)
      setModalState(false);
    }, 1000)
  }, [pending, success, failure]);

  useEffect(() => {
    if (updateState.success) {
      if (_.isEmpty(designData)) readDesignData(uid);
    }
  }, [updateState.success, updateState.failure]);

  useEffect(() => {
    updateState.pending && setTimeout(() => {
      setModalState(true);
      if (!_.isEmpty(designData)) {
        designData = undefined;
        setDesignValue(undefined);
      }
    }, 1000)
  }, [updateState.pending]);

  useEffect(() => {
    if (deleteState.success) {
      if (_.isEmpty(designData)) readDesignData(uid);
    }
  }, [deleteState.success, deleteState.failure]);

  useEffect(() => {
    deleteState.pending && setTimeout(() => {
      setModalState(true);
      if (!_.isEmpty(designData)) {
        designData = undefined;
        setDesignValue(undefined);
      }
    }, 1000)
  }, [deleteState.pending]);

  useEffect(() => {
    if (archiveState.success) {
      if (_.isEmpty(designData)) readDesignData(uid);
    }
  }, [archiveState.success, archiveState.failure]);

  useEffect(() => {
    archiveState.pending && setTimeout(() => {
      setModalState(true);
      if (!_.isEmpty(designData)) {
        designData = undefined;
        setDesignValue(undefined);
      }
    }, 1000)
  }, [archiveState.pending]);


  useEffect(() => {
    if (!_.isEmpty(uidBuffer)) {
      setbufferFlag(false);
    }
  }, [uidBuffer])

  const _lastUidOpener = () => {
    if (!_.isEmpty(uidBuffer) && !bufferFlag) {
      let lastUid = `https://webfront.joto.app/${uidBuffer}`
      const newWindow = window.open(lastUid, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
  }

  const _sourceImageOpen = (sourceUrl) => {
    const newWindow = window.open(sourceUrl, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleChange = (category, object, value) => {
    switch (category) {
      case 'measurements':
        designValue[`${category}`][`${object}`] = parseFloat(value)
        let front_ratio;
        if (object === 'height') {
          front_ratio = designValue[`measurements`][`width`] / parseFloat(value)
          if (front_ratio === Infinity || isNaN(front_ratio)) front_ratio = 0;
          console.log(front_ratio);
        }
        else if (object === 'width') {
          front_ratio = parseFloat(value) / designValue[`measurements`][`height`]
          if (front_ratio === Infinity || isNaN(front_ratio)) front_ratio = 0;
          console.log(front_ratio);
        }

        designValue[`measurements`][`front_ratio`] = front_ratio;
        setDesignValue(designValue)
        break;
      case 'brightness':
        value = Number(value)
        if (value === 0 || value === 1) {
          if (value === 0) {
            designValue[`${category}`][`${object}`] = false
          } else {
            designValue[`${category}`][`${object}`] = true
          }
          setDesignValue(designValue)
        }
        break;
      case 'style':
        value = Number(value)
        if (value === 0 || value === 1) {
          if (value === 0) {
            designValue[`${category}`][`${object}`] = false
          } else {
            designValue[`${category}`][`${object}`] = true
          }
          setDesignValue(designValue)
        }
        break;
      default:
        break;
    }
  }

  const objectChange = (article, parameter, value) => {
    designValue.objects.forEach(element => {
      if (Object.keys(element) == article) {
        element[`${article}`][`${parameter}`] = Number(value)
      }
    });
    setDesignValue(designValue);
  }

  const _updateDesignData = () => {
    updateDesignData(designValue);
  }
  const _deleteDesignData = () => {
    deleteDesignData(designValue);
  }

  const _archiveDesignData = () => {
    archiveDesignData(designValue);
  }

  return (

    <div>
      <ModalView open={modalState} headerText={"retrieving design"} />
      {designValue &&
        <div className={classes.container}>
          <DataPage
            designData={designValue}
            handleChange={handleChange}
            objectChange={objectChange}
            lastUidOpener={_lastUidOpener}
            disableFlag={bufferFlag}
            sourceImageOpen={_sourceImageOpen}
          />
          <Buttons updateDesignData={_updateDesignData}
            deleteDesignData={_deleteDesignData}
            archiveDesignData={_archiveDesignData}
          />
        </div>}
    </div>
  );
};

/* STYLING */
const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
}));


const mapStateToProps = state => ({
  designData: state.designData,
  updateState: state.updateState,
  designDataState: state.designDataState,
  deleteState: state.deleteState,
  archiveState: state.archiveState
});

const mapDispatchToProps = (dispatch) => ({
  readDesignData: (uid) => dispatch(actions.readDesignData(uid)),
  updateDesignData: (designData) => dispatch(actions.updateDesignData(designData)),
  deleteDesignData: (designData) => dispatch(actions.deleteDesignData(designData)),
  archiveDesignData: (designData) => dispatch(actions.archiveDesignData(designData)),
})


export default connect(mapStateToProps, mapDispatchToProps)(App);
