import React, { useState, useEffect } from 'react';
import { useParams } from "@reach/router";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Box, Card, CardContent, CardMedia, ListItem, Checkbox, Link, Table, TableBody, TableCell, TableRow, TextField } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import AppBar from '@mui/material/AppBar';
import AddBoxIcon from '@material-ui/icons/AddCircleTwoTone';

import _ from 'lodash';
import actions from '../redux/actions/actions';

const Img = styled('img')({
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    resizeMode: 'cover',
    height: '450px',
});

const ObjectRow = ({ designData, article, objectChange }) => {
    const classes = useStyles();

    return (

        designData &&
        <div>

            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell></TableCell>

                        <TableCell>
                            <Typography className={classes.uid_text}>
                                frontal
                            </Typography>
                        </TableCell>

                        <TableCell>
                            <Typography className={classes.uid_text}>
                                left
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography className={classes.uid_text}>
                                right
                            </Typography>
                        </TableCell>

                        <TableCell>
                            <Typography className={classes.uid_text}>
                                sum
                            </Typography>
                        </TableCell>

                    </TableRow>

                    <TableRow className={classes.measurement}>
                        <TableCell className={classes.col}>
                            <Typography className={classes.rowTitle}>
                                {`${Object.keys(article)[0]}:`}
                            </Typography>
                        </TableCell>

                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    inputProps= {
                                        { inputMode: 'numeric', pattern: '[0-1]*' }
                                    }
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={article[`${Object.keys(article)[0]}`].frontal}
                                    variant="filled"
                                    onChange={e => objectChange(`${Object.keys(article)[0]}`, 'frontal', e.target.value)}
                                />
                            }

                        </TableCell>

                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    inputProps= {
                                        { inputMode: 'numeric', pattern: '[0-1]*' }
                                    }
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={article[`${Object.keys(article)[0]}`].left}
                                    variant="filled"
                                    onChange={e => objectChange(`${Object.keys(article)[0]}`, 'left', e.target.value)}
                                />
                            }
                        </TableCell>

                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    inputProps= {
                                        { inputMode: 'numeric', pattern: '[0-1]*' }
                                    }
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={article[`${Object.keys(article)[0]}`].right}
                                    variant="filled"
                                    onChange={e => objectChange(`${Object.keys(article)[0]}`, 'right', e.target.value)}
                                />
                            }

                        </TableCell>
                        <TableCell className={classes.col}>
                            {
                                <TextField
                                    inputProps= {
                                        { inputMode: 'numeric', pattern: '[0-1]*' }
                                    }
                                    disabled={false}
                                    style={{ justifyContent: 'center', backgroundColor: '#B1B4C4' }}
                                    defaultValue={article[`${Object.keys(article)[0]}`].sum}
                                    variant="filled"
                                    onChange={e => objectChange(`${Object.keys(article)[0]}`, 'sum', e.target.value)}
                                />
                            }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    uid: {
        paddingTop: '40px',
        textAlign: 'center',
        alignItems: 'center'
    },
    uid_text: {
        textAlign: 'left',
        fontSize: '12'
    },
    rowTitle: {
        textAlign: 'center',
        fontSize: '12'
    },
    image: {
        alignContent: 'center',
        alignItems: 'center'
    },
    measurementr: {
        display: 'flex',
        flexDirection: 'row',
    },
    col: {
        width: '20%'
    },
}));



export default ObjectRow
