const RETRIEVE_DESIGNS = 'RETRIEVE_DESIGNS';

const RETRIEVE_DESIGNS_PENDING = 'RETRIEVE_DESIGNS_PENDING';
const RETRIEVE_DESIGNS_SUCCESS = 'RETRIEVE_DESIGNS_SUCCESS';
const RETRIEVE_DESIGNS_FAILURE = 'RETRIEVE_DESIGNS_FAILURE';

const READ_DESIGNS = 'READ_DESIGNS';

const READ_DESIGNS_PENDING = 'READ_DESIGNS_PENDING';
const READ_DESIGNS_SUCCESS = 'READ_DESIGNS_SUCCESS';
const READ_DESIGNS_FAILURE = 'READ_DESIGNS_FAILURE';

const UPDATE_DESIGNS = 'UPDATE_DESIGNS';

const UPDATE_DESIGNS_PENDING = 'UPDATE_DESIGNS_PENDING';
const UPDATE_DESIGNS_SUCCESS = 'UPDATE_DESIGNS_SUCCESS';
const UPDATE_DESIGNS_FAILURE = 'UPDATE_DESIGNS_FAILURE';

const DELETE_DESIGNS = 'DELETE_DESIGNS';

const DELETE_DESIGNS_PENDING = 'DELETE_DESIGNS_PENDING';
const DELETE_DESIGNS_SUCCESS = 'DELETE_DESIGNS_SUCCESS';
const DELETE_DESIGNS_FAILURE = 'DELETE_DESIGNS_FAILURE';

const ARCHIVE_DESIGNS = 'ARCHIVE_DESIGNS';

const ARCHIVE_DESIGNS_PENDING = 'ARCHIVE_DESIGNS_PENDING';
const ARCHIVE_DESIGNS_SUCCESS = 'ARCHIVE_DESIGNS_SUCCESS';
const ARCHIVE_DESIGNS_FAILURE = 'ARCHIVE_DESIGNS_FAILURE';


export {
    
    RETRIEVE_DESIGNS,
    RETRIEVE_DESIGNS_PENDING,
    RETRIEVE_DESIGNS_SUCCESS,
    RETRIEVE_DESIGNS_FAILURE,

    READ_DESIGNS,
    READ_DESIGNS_PENDING,
    READ_DESIGNS_SUCCESS,
    READ_DESIGNS_FAILURE,

    UPDATE_DESIGNS,
    UPDATE_DESIGNS_PENDING,
    UPDATE_DESIGNS_SUCCESS,
    UPDATE_DESIGNS_FAILURE,

    DELETE_DESIGNS,
    DELETE_DESIGNS_PENDING,
    DELETE_DESIGNS_SUCCESS,
    DELETE_DESIGNS_FAILURE,

    ARCHIVE_DESIGNS,
    ARCHIVE_DESIGNS_PENDING,
    ARCHIVE_DESIGNS_SUCCESS,
    ARCHIVE_DESIGNS_FAILURE
};