import {
    READ_DESIGNS,
    RETRIEVE_DESIGNS,
    UPDATE_DESIGNS,
    DELETE_DESIGNS,
    ARCHIVE_DESIGNS
  
  } from '../constants/constants';
  
  const baseUrl = 'https://tool.joto.app'
  //const baseUrl = 'http://localhost:8070'
  
  const readDesignData = (uid) => ({
    type: READ_DESIGNS,
    apiPackage: {
      method: 'GET',
      parameters: `${baseUrl}/read/${uid}`
    }
  });
  const retrieveDesignData = (user) => ({
    type: RETRIEVE_DESIGNS,
    apiPackage: {
      method: 'GET',
      parameters: `${baseUrl}/retrieve/${user}`
    }
  });
  
  const updateDesignData = (designData) => ({
    type: UPDATE_DESIGNS,
    apiPackage: {
      method: 'PUT',
      parameters: `${baseUrl}/update`,
      body: designData
    }
  });
  
  const deleteDesignData = (designData) => ({
    type: DELETE_DESIGNS,
    apiPackage: {
      method: 'PUT',
      parameters: `${baseUrl}/delete`,
      body: designData
    }
  })

  const archiveDesignData = (designData) => ({
    type: ARCHIVE_DESIGNS,
    apiPackage: {
      method: 'PUT',
      parameters: `${baseUrl}/archive`,
      body: designData
    }
  })



  
  const actions = {
    readDesignData,
    retrieveDesignData,
    updateDesignData,
    deleteDesignData,
    archiveDesignData
  }
  
  export default actions;