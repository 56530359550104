import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Table, TableBody, TableRow, TableCell, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';




const Buttons = ({ archiveDesignData, updateDesignData, deleteDesignData }) => {

  const classes = useStyles();


  return (
    <div>
      <Box className={classes.detailContainer}>
        <Table>
          <TableBody>
            <TableRow  className={classes.buttonRow} >
              <TableCell className={classes.buttonCell}>
                <Button variant="contained"
                  className={classes.buttonBox_red}
                  onClick={() => deleteDesignData()}>  &nbsp;Delete design
                </Button>
              </TableCell>
              <TableCell className={classes.buttonCell}>
                <Button variant="contained"
                  className={classes.buttonBox_orange}
                  onClick={() => archiveDesignData()}>  &nbsp;Archive design
                </Button>
              </TableCell>
              <TableCell className={classes.buttonCell}>
                <Button
                  onClick={() => updateDesignData() }
                  variant="contained"
                  className={classes.buttonBox_blue}> &nbsp;Update design
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </div>

  );
};

const useStyles = makeStyles(theme => ({


  detailContainer: {
    paddingTop: '100px',
    paddingBottom: '80px',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttonCell: {
    borderBottom: 'none',
    textAlign: 'center',
    width: '50%',
  },
  buttonBox_red: {
    width: '280px',
    backgroundColor: '#fc4b4b',
    textAlign: 'center'
  },
  buttonBox_blue: {
    width: '280px',
    backgroundColor: '#6ec6ff',
    textAlign: 'center'
  },
  buttonBox_orange: {
    width: '280px',
    backgroundColor: '#ff9029',
    textAlign: 'center'
  },

}));

export default Buttons;
